import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function Navbar() {
    const { t } = useTranslation();
    return (
        <nav className="headerSection">
            <ul>
                <li><NavLink exact to="/" id="home" activeClassName="active">{t('homeNav.label')}</NavLink></li>
                <li><NavLink exact to="/about" id="about" activeClassName="active">{t('aboutNav.label')}</NavLink></li>
                <li><NavLink exact to="/experience" id="experience" activeClassName="active">{t('experienceNav.label')}</NavLink></li>
                <li><NavLink exact to="/skills" id="skills" activeClassName="active">{t('skillsNav.label')}</NavLink></li>
            </ul>
        </nav>
    )
}
    
export default Navbar
    