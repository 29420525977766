import React from 'react';
import RestaurantCuisine from './RestaurantCuisine';

class RestaurantType extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showRestaurantCuisine: false
            };

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            this.setState({value: event.target.value,
                showRestaurantCuisine: true});
        }

        render() {
            const { showRestaurantCuisine } = this.state;
            return (
                <div id="rqsRestaurantTypeItem">
                    <div className="rqsInverseDiv" onChange={this.handleChange}>
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsRestaurantType">{this.props.rqsRestaurantTypeLabel} {this.props.restaurantNameInput}?</label>
                            <div>
                                <select id="rqsRestaurantType" name="rqsRestaurantType" className="rqsInput rqsSelect" required>
                                    <option defaultValue>Restaurant Type</option>
                                    <option value="fineDining">Fine Dining</option>
                                    <option value="casualDining">Casual Dining</option>
                                    <option value="fastCasual">Fast Casual</option>
                                    <option value="foodTruck">Food Truck/Cart/Stand</option>
                                    <option value="cafe">Cafe</option>
                                    <option value="pub">Pub</option>
                                    <option value="buffet">Buffet</option>
                                    <option value="diner">Diner</option>
                                    <option value="popUp">Pop-up Restaurant</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {showRestaurantCuisine && <RestaurantCuisine
                        restaurantNameInput={this.props.restaurantNameInput}
                        rqsRestaurantCuisineLabel={this.props.rqsRestaurantCuisineLabel} 
                        rqsRestaurantHoursLabel={this.props.rqsRestaurantHoursLabel} 
                        rqsRestaurantLogoLabel={this.props.rqsRestaurantLogoLabel} 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo} 
                        rqsSkipLabel={this.props.rqsSkipLabel} 
                        rqsRestaurantColorSchemeLabel={this.props.rqsRestaurantColorSchemeLabel} 
                        rqsRestaurantGenerateColorLabel={this.props.rqsRestaurantGenerateColorLabel} 
                        rqsRestaurantAddColorLabel={this.props.rqsRestaurantAddColorLabel} 
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel} 
                        rqsRestaurantSeatingChartLabel={this.props.rqsRestaurantSeatingChartLabel} 
                        rqsRestaurantSeatingChartYesGenerateLabel={this.props.rqsRestaurantSeatingChartYesGenerateLabel}
                        rqsRestaurantSeatingChartYesMakeLabel={this.props.rqsRestaurantSeatingChartYesMakeLabel} 
                        rqsRestaurantSeatingChartYesLaterLabel={this.props.rqsRestaurantSeatingChartYesLaterLabel} 
                        rqsRestaurantSeatingChartNoNeedLabel={this.props.rqsRestaurantSeatingChartNoNeedLabel} 
                        rqsRestaurantSeatingChartLeaveLabel={this.props.rqsRestaurantSeatingChartLeaveLabel} 
                        rqsRestaurantDeliveryOptionsLabel={this.props.rqsRestaurantDeliveryOptionsLabel} 
                        rqsRestaurantDineIn={this.props.rqsRestaurantDineIn} 
                        rqsRestaurantPickUpLabel={this.props.rqsRestaurantPickUpLabel}  
                        rqsRestaurantInHouseDeliveryLabel={this.props.rqsRestaurantInHouseDeliveryLabel}
                        rqsRestaurantThirdPartyDeliveryLabel={this.props.rqsRestaurantThirdPartyDeliveryLabel}  
                        rqsRestaurantThirdPartyDeliveryLeaveLabel={this.props.rqsRestaurantThirdPartyDeliveryLeaveLabel}
                    />}
                </div>
                
            )
        }
    }
    
export default RestaurantType