import React, { Suspense } from 'react';
import './App.css';
import './i18n';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './contents/Home';
import Footer from './components/Footer';
import Description from './components/Description';
import Experience from './components/Experience';
import Skills from './components/Skills';
import SquareProposal from './components/squareProposal/SquareProposal';
import RQS from './components/squareProposal/RQS';


function App() {
  return (
    <Suspense fallback={null}>
    <Router>
    <div className="App">
      <div className="regularApp">
        <Navbar />
        <Route exact path="/">
        <Home />
        </Route>
        <Route exact path="/about">
        <Description />
        </Route>
        <Route exact path="/experience">
        <Experience />
        </Route>
        <Route exact path="/skills">
        <Skills />
        </Route>
        <Route exact path="/squareproposal">
        <SquareProposal />
        </Route>
        <Footer/>
        </div>
    <Route exact path="/rqs">
    <RQS />
    </Route>
    </div>
    </Router>
    </Suspense>
    );
  }
  
  export default App;
  