import React from 'react';
import { useTranslation } from "react-i18next";
import RQSlanding from './RQS/RQSlanding';
import SquareLogoBlack from '../../img/Square_Logo_Black.png';
function RQS() {
        const { t } = useTranslation();

        return (
            <div className="rqs">
                <div className="rqsLogoDiv">
                    <img className="rqsSquareLogo" src={SquareLogoBlack} alt="Square Black Logo"/>
                </div>
                <div id="rqsLanding">
                    <RQSlanding 
                        rqsNameLabel={t('rqsName.label')} 
                        rqsRestaurantNameLabel={t('rqsRestaurantName.label')} 
                        rqsContactPreferenceLabel={t('rqsContactPreference.label')} 
                        rqsPhoneNumberLabel={t('rqsPhoneNumber.label')} 
                        rqsEmailLabel={t('rqsEmail.label')} 
                        rqsFirstNameLabel={t('rqsFirstName.label')} 
                        rqsLastNameLabel={t('rqsLastName.label')} 
                        rqsEmailOptionLabel={t('rqsEmailOption.label')} 
                        rqsPhoneOptionLabel={t('rqsPhoneOption.label')} 
                    />
                </div>
                <div id="rqsStaff" hidden>
                </div>
                <div id="rqsComplete" hidden>
                </div>
           </div>
        )
        
    }
    
export default RQS
    