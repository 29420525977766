import { MDBIcon } from 'mdbreact';
import React from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

function Skills() {
    
    const state={
        'topskills':['java','javascript','html','css','sql','react js','angular',
        'node js', 'handlebars', 'git', 'spring applications', 'rest api', 'jpa', 'hibernate',
        'ajax', 'soapui', 'xml', 'ios and android development', 'web development', ],
        'otherskills':['gradle', 'apache tomcat', 'npm', 'junit', 'selenium', 'docker', 'kubernetes', 'python',
        'go', 'c', 'c++', 'agile', 'dev-ops', 'jenkins', 'rabbitmq', 'bitbucket','jira', 'microsoft or google office suite'

        ]
    };
    
    const { t } = useTranslation();

    return (
        <div className="condiv">
            <h1 className="subtopic"><strong>{t('skillsTitle.label')}</strong></h1>
            <div><p>{t('skillsHeader.label')}</p>
                <p>{t('skillsDesc.label')}</p></div>
            <div className="topSkillsDiv">
                <ul>
                {state.topskills.map((value)=>{
                    let upperCaseVal = value.toUpperCase();
                    let skillsClass = "skills " + value;
                    return (<li key={upperCaseVal}><p>{upperCaseVal}</p>
                            <div className="skillsContainer">    
                                <div className={skillsClass}></div>
                            </div><br/>
                        </li>);
                })}
                </ul>
            </div><br/>
            <p>{t('otherSkillsDesc.label')}</p>
            <div className="otherSkillsDiv">
                <ul>
                {state.otherskills.map((value)=>{
                    let upperCaseVal = value.toUpperCase();
                    if(value !== 'microsoft or google office suite') {
                        upperCaseVal += ', ';
                    }
                    return (<li key={value}>{upperCaseVal}</li>);
                })}
                </ul>
            </div>
            <br/>
            <Link to="/" className="downButton"><MDBIcon icon="arrow-circle-up" size="4x"/></Link>
        </div>
        )
    }

export default Skills
    