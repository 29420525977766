import React, {useState} from 'react';
import HighlightCard from './HighlightCard';
import { useTranslation } from "react-i18next";
import { Modal, Button, Image } from 'react-bootstrap';
import xmlComparison from '../img/xml_comparison.jpg';
import lspeaLookup from '../img/lspea_lookup.png';
import oldVinDashboard from '../img/old_vin_dashboard.png';
import vinDashboardRework from '../img/vin_dashboard_rework.png';
import spanishDairylandLogin from '../img/spanish_dairyland_login.jpg';

function SentryExperience() {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="sentryExperience">
            <button className="experienceButton" onClick={handleShow}><HighlightCard title={t('sentryTitle.label')} where={t('sentry.label')} from={t('sentryStart.label')} to={t('sentryEnd.label')}/></button>
            
            <Modal size="lg" show={show} onHide={handleClose}>
                <div className="modalcondiv">
                <Modal.Header closeButton>
                <Modal.Title><h2>{t('sentry.label')} - {t('sentryTitle.label')}</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="sentryIntro">
                        <p>{t('sentryIntro.label')}</p>
                    </div>
                    <div className="mobileAppTranslation">
                        <h3>{t('sentryMobileAppTitle.label')}</h3>
                        <p>{t('sentryMobileAppDescription.label')}</p>
                        <Image onClick={()=> window.open(spanishDairylandLogin, "_blank")} fluid src={spanishDairylandLogin} alt="Spanish Mobile Login"/>
                    </div>
                    <br/>
                    <div className="xmlJsonComparator">
                        <h3>{t('jsonXmlComparatorTitle.label')}</h3>
                        <Image onClick={()=> window.open(xmlComparison, "_blank")} fluid src={xmlComparison} alt="Json Xml Comparator"/>
                        <p>{t('jsonXmlComparatorDescription.label')}</p>
                        
                    </div>
                    <div className="lspeaLookup">
                        <h3>{t('lspeaLookupTitle.label')}</h3>
                        <Image onClick={()=> window.open(lspeaLookup, "_blank")} fluid src={lspeaLookup} alt="LSPEA Lookup"/>
                        <p>{t('lspeaLookupDescription.label')}</p>
                    </div>
                    <div className="vinDashboard">
                        <h3>{t('vinDashboardTitle.label')}</h3>
                        <Image onClick={()=> window.open(vinDashboardRework, "_blank")} fluid src={vinDashboardRework} alt="VIN Dashboard Rework"/>
                        <p>{t('vinDashboardDescription.label')}</p>
                        <Image onClick={()=> window.open(oldVinDashboard, "_blank")} fluid src={oldVinDashboard} alt="Old VIN Dashboard"/>
                    </div>
                    <br/>
                    <div className="olderWork">
                        <h3>{t('olderWorkTitle.label')}</h3>
                        <p>{t('olderWorkDescription.label')}</p>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                <Button block className="closeModalButton" variant="outline-dark" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
                </div>
            </Modal>
        </div>
    )
}
    
export default SentryExperience