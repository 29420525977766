import React from 'react';
import BuildMenu from './BuildMenu';

class UploadPhoto extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showBuildMenu: false
            };

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            event.preventDefault();
            alert("This is where the user would upload a picture of the relevant requested items from their device.");
            this.setState({showBuildMenu: true});
        }

        render() {
            const { showBuildMenu } = this.state;
            return (
                <div id="rqsMenuUploadPhoto">
                    <div className="rqsDiv">
                        <div className="rqsInputContainer" >
                            <button onClick={this.handleChange}>Upload</button>
                        </div>
                    </div>
                    {showBuildMenu && <BuildMenu
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo}
                        rqsIntegrateLabel={this.props.rqsIntegrateLabel}
                        rqsMenuUploadFromPhotoLabel={this.props.rqsMenuUploadFromPhotoLabel}
                        rqsMenuBuildLabel={this.props.rqsMenuBuildLabel}
                        rqsMenuNewItemLabel={this.props.rqsMenuNewItemLabel}
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel}
                    />}
                </div>
                
            )
        }
    }
    
export default UploadPhoto