import React from 'react';
import ThirdPartyIntegrateItem from './ThirdPartyIntegrateItem';
import UploadMenuFromPhoto from './UploadMenuFromPhoto';

class ThirdPartyMenuItem extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showThirdPartyIntegrateItem: false, showUploadMenu: false
            };

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            if(event.target.value === "Yes") {
                this.setState({value: event.target.value,
                    showThirdPartyIntegrateItem: true, showUploadMenu: false});
            } else {
                this.setState({value: event.target.value,
                    showThirdPartyIntegrateItem: false, showUploadMenu: true});
            }  
        }

        render() {
            const { showThirdPartyIntegrateItem, showUploadMenu } = this.state;
            return (
                <div id="rqsThirdPartyMenuItem">
                    <div className="rqsDiv" onChange={this.handleChange}>
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsThirdPartyMenu">{this.props.rqsThirdPartyLabel}</label>
                            <br />
                            <span className="yesNoSpan">
                                <label className="rqsInput" htmlFor="rqsThirdPartyMenuYes">
                                <input id="rqsThirdPartyMenuYes" name="rqsThirdPartyMenuYes" className="rqsInput rqsCheckbox radio" type="radio" value={this.props.rqsYes} checked={this.state.value === this.props.rqsYes}/>
                                {this.props.rqsYes}  </label>
                            </span>
                            <span className="yesNoSpan">
                                <label className="rqsInput" htmlFor="rqsThirdPartyMenu">
                                <input id="rqsThirdPartyMenu" name="rqsThirdPartyMenu" className="rqsInput rqsCheckbox radio" type="radio" value={this.props.rqsNo} checked={this.state.value === this.props.rqsNo}/>
                                {this.props.rqsNo}</label>
                            </span>
                        </div>
                    </div>
                    {showThirdPartyIntegrateItem && <ThirdPartyIntegrateItem
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo}
                        rqsIntegrateLabel={this.props.rqsIntegrateLabel}
                        rqsMenuUploadFromPhotoLabel={this.props.rqsMenuUploadFromPhotoLabel}
                        rqsMenuBuildLabel={this.props.rqsMenuBuildLabel}
                        rqsMenuNewItemLabel={this.props.rqsMenuNewItemLabel}
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel}
                    />}
                    {showUploadMenu && <UploadMenuFromPhoto
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo}
                        rqsIntegrateLabel={this.props.rqsIntegrateLabel}
                        rqsMenuUploadFromPhotoLabel={this.props.rqsMenuUploadFromPhotoLabel}
                        rqsMenuBuildLabel={this.props.rqsMenuBuildLabel}
                        rqsMenuNewItemLabel={this.props.rqsMenuNewItemLabel}
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel}
                    />}
                </div>
                
            )
        }
    }
    
export default ThirdPartyMenuItem