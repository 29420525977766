import React from 'react';
import CreatableSelect from 'react-select/creatable';
import menuOptions from '../../../../../json/menus.json';
import displayGroupOptions from '../../../../../json/displayGroup.json';
import itemOptions from '../../../../../json/menuItems.json';
class MenuItems extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: ''};
            
            this.menuOptions = menuOptions;
            this.displayGroupOptions = displayGroupOptions;
            this.itemOptions = itemOptions;
            this.handleChange = this.handleChange.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
        }

        handleChange(event) {
            this.setState({value: event.target.value});
        }
        
        handleSubmit(event) {
            alert('A name was submitted: ' + this.state.value);
            event.preventDefault();
        }

        render() {
            return (
                <div id="menuItems">
                    <div className="rqsMenuInverseDiv" onChange={this.handleChange}>
                        <div className="rqsInputContainer">
                            <div id="rqsItemMenuSelect">
                                <CreatableSelect
                                    isClearable
                                    options={this.menuOptions}
                                    id="rqsItemMenuSelect" 
                                    name="rqsItemMenuSelect" 
                                    className="rqsInput rqsSelect" 
                                    placeholder="Select or add menu"
                                    required
                                />
                            </div>
                            <div id="rqsItemDisplayGroupSelect">
                                <CreatableSelect
                                    isClearable
                                    options={this.displayGroupOptions}
                                    id="rqsItemDisplayGroupSelect" 
                                    name="rqsItemDisplayGroupSelect" 
                                    className="rqsInput rqsSelect"
                                    placeholder="Select or add display group"
                                    required
                                />
                            </div>
                            <div id="rqsItemItemSelect">
                                <CreatableSelect
                                    isClearable
                                    options={this.itemOptions}
                                    id="rqsItemItemSelect" 
                                    name="rqsItemItemSelect" 
                                    className="rqsInput rqsSelect"
                                    placeholder="Select or add item"
                                    required
                                />
                            </div>
                            <div id="rqsItemSubmit">
                                <button id="rqsItemSubmitButton">New Menu Item</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    
export default MenuItems