import React, {useState} from 'react';
import HighlightCard from './HighlightCard';
import { useTranslation } from "react-i18next";
import { Modal, Button, Image } from 'react-bootstrap';
import fhKingHarvest from '../img/fhking_harvest.jpg';

function FHKingExperience() {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="fhkingExperience">
            <button className="experienceButton" onClick={handleShow}><HighlightCard title={t('fhkingTitle.label')} where={t('fhking.label')} from={t('fhkingStart.label')} to={t('fhkingEnd.label')}/></button>
            
            <Modal size="lg" show={show} onHide={handleClose}>
                <div className="modalcondiv">
                <Modal.Header closeButton>
                <Modal.Title><h2>{t('fhking.label')} - {t('fhkingTitle.label')}</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image onClick={()=> window.open(fhKingHarvest, "_blank")} fluid src={fhKingHarvest} alt="FH King Harvest"/>
                    <p>{t('fhkingDescription1.label')}</p>
                    <p>{t('fhkingDescription2.label')}</p>
                    <p>{t('fhkingDescription3.label')}</p>
                    <p>{t('fhkingDescription4.label')}<a className="resumeLink" href="https://willhofkamp.github.io/folder/WilliamHofkampResume.pdf"><u>{t('here.label')}</u></a></p>
                </Modal.Body>
                <Modal.Footer>
                <Button block className="closeModalButton" variant="outline-dark" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
                </div>
            </Modal>
        </div>
    )
}
    
export default FHKingExperience