import React from 'react';
import UploadMenuFromPhoto from './UploadMenuFromPhoto';
import ThirdPartyOptions from './ThirdPartyOptions';
class ThirdPartyIntegrateItem extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showUploadMenuFromPhoto: false, showThirdPartyOptions: false};
            
            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            if(event.target.value === "yes") {
                this.setState({value: event.target.value,
                    showThirdPartyOptions: true,
                    UploadMenuFromPhoto: false });
            } else {
                this.setState({value: event.target.value,
                    showThirdPartyOptions: false,
                    UploadMenuFromPhoto: true });
            }
        }

        render() {
            const { showUploadMenuFromPhoto, showThirdPartyOptions } = this.state;
            return (
                <div id="rqsThirdPartyIntegrateItem">
                    <div className="rqsInverseDiv" onChange={this.handleChange}>
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsThirdPartyIntegrate">{this.props.rqsIntegrateLabel}</label>
                            <br />
                            <span className="yesNoSpan">
                                <label className="rqsInput" htmlFor="rqsThirdPartyIntegrate"><input id="rqsThirdPartyIntegrateYes" name="rqsThirdPartyIntegrate" className="rqsInput rqsCheckbox radio" type="radio" value="yes" />
                                {this.props.rqsYes}</label>
                            </span>
                            <span className="yesNoSpan">
                                <label className="rqsInput" htmlFor="rqsThirdPartyIntegrate"><input id="rqsThirdPartyIntegrateNo" name="rqsThirdPartyIntegrate" className="rqsInput rqsCheckbox radio" type="radio" value="no" />
                                {this.props.rqsNo}</label>
                            </span>
                        </div>
                    </div>
                    {showThirdPartyOptions && <ThirdPartyOptions 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo}
                        rqsMenuUploadFromPhotoLabel={this.props.rqsMenuUploadFromPhotoLabel}
                        rqsMenuBuildLabel={this.props.rqsMenuBuildLabel}
                        rqsMenuNewItemLabel={this.props.rqsMenuNewItemLabel}
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel}
                    />}
                    {showUploadMenuFromPhoto && <UploadMenuFromPhoto 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo}
                        rqsMenuUploadFromPhotoLabel={this.props.rqsMenuUploadFromPhotoLabel}
                        rqsMenuBuildLabel={this.props.rqsMenuBuildLabel}
                        rqsMenuNewItemLabel={this.props.rqsMenuNewItemLabel}
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel}
                    />}
                </div>
            )
        }
    }
    
export default ThirdPartyIntegrateItem