import React from 'react';
import { useTranslation } from "react-i18next";
import RQSmenu from '../../RQSmenu';
function RQS() {
        const { t } = useTranslation();

        return (
            <div id="rqsMenu" >
                <RQSmenu 
                    rqsYes={t('rqsYes.label')} 
                    rqsNo={t('rqsNo.label')} 
                    rqsThirdPartyLabel={t('rqsThirdParty.label')} 
                    rqsIntegrateLabel={t('rqsIntegrate.label')} 
                    rqsMenuUploadFromPhotoLabel={t('rqsMenuUploadFromPhoto.label')} 
                    rqsMenuBuildLabel={t('rqsMenuBuild.label')} 
                    rqsMenuNewItemLabel={t('rqsMenuNewItem.label')} 
                    rqsLooksGoodLabel={t('rqsLooksGood.label')}
                />
            </div>
        )
        
    }
    
export default RQS
    