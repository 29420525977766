import React from 'react';
import { useTranslation } from "react-i18next";
import RQSrestaurantInfo from '../../RQSrestaurantInfo';
function RestaurantInfoContainer(props) {
        const { t } = useTranslation();

        return (
            <div id="rqsRestaurantInfo" >

                    <RQSrestaurantInfo 
                        rqsRestaurantName={props.restaurantNameInput}
                        rqsRestaurantLocationLabel={t('rqsRestaurantLocation.label')} 
                        rqsRestaurantTypeLabel={t('rqsRestaurantType.label')} 
                        rqsRestaurantCuisineLabel={t('rqsRestaurantCuisine.label')} 
                        rqsRestaurantHoursLabel={t('rqsRestaurantHours.label')} 
                        rqsRestaurantLogoLabel={t('rqsRestaurantLogo.label')} 
                        rqsYes={t('rqsYes.label')} 
                        rqsNo={t('rqsNo.label')} 
                        rqsSkipLabel={t('rqsSkip.label')} 
                        rqsRestaurantColorSchemeLabel={t('rqsRestaurantColorScheme.label')} 
                        rqsRestaurantGenerateColorLabel={t('rqsRestaurantGenerateColor.label')} 
                        rqsRestaurantAddColorLabel={t('rqsRestaurantAddColor.label')} 
                        rqsLooksGoodLabel={t('rqsLooksGood.label')} 
                        rqsRestaurantSeatingChartLabel={t('rqsRestaurantSeatingChart.label')} 
                        rqsRestaurantSeatingChartYesGenerateLabel={t('rqsRestaurantSeatingChartYesGenerate.label')}
                        rqsRestaurantSeatingChartYesMakeLabel={t('rqsRestaurantSeatingChartYesMake.label')} 
                        rqsRestaurantSeatingChartYesLaterLabel={t('rqsRestaurantSeatingChartYesLater.label')} 
                        rqsRestaurantSeatingChartNoNeedLabel={t('rqsRestaurantSeatingChartNoNeed.label')} 
                        rqsRestaurantSeatingChartLeaveLabel={t('rqsRestaurantSeatingChartLeave.label')} 
                        rqsRestaurantDeliveryOptionsLabel={t('rqsRestaurantDeliveryOptions.label')} 
                        rqsRestaurantDineIn={t('rqsRestaurantDineIn.label')} 
                        rqsRestaurantPickUpLabel={t('rqsRestaurantPickUp.label')}  
                        rqsRestaurantInHouseDeliveryLabel={t('rqsRestaurantInHouseDelivery.label')}
                        rqsRestaurantThirdPartyDeliveryLabel={t('rqsRestaurantThirdPartyDelivery.label')}  
                        rqsRestaurantThirdPartyDeliveryLeaveLabel={t('rqsRestaurantThirdPartyDeliveryLeave.label')}
                    
                    />
                </div>
        )
        
    }
    
export default RestaurantInfoContainer
    