import React from 'react';
import RestaurantLogo from './RestaurantLogo';
import HoursInputs from './HoursInputs';

class RestaurantHours extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showRestaurantLogo: false
            };

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            event.preventDefault();
            this.setState({showRestaurantLogo: true});
        }

        render() {
            const { showRestaurantLogo } = this.state;
            return (
                <div id="rqsRestaurantHoursItem">
                    <div className="rqsInverseDiv">
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsRestaurantHours">{this.props.rqsRestaurantHoursLabel} {this.props.restaurantNameInput} open?</label>
                            <br />
                            <HoursInputs />
                            <br />
                            <button onClick={this.handleChange}>{this.props.rqsLooksGoodLabel}</button>
                        </div>
                    </div>
                    {showRestaurantLogo && <RestaurantLogo
                        restaurantNameInput={this.props.restaurantNameInput}
                        rqsRestaurantCuisineLabel={this.props.rqsRestaurantCuisineLabel} 
                        rqsRestaurantHoursLabel={this.props.rqsRestaurantHoursLabel} 
                        rqsRestaurantLogoLabel={this.props.rqsRestaurantLogoLabel} 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo} 
                        rqsSkipLabel={this.props.rqsSkipLabel} 
                        rqsRestaurantColorSchemeLabel={this.props.rqsRestaurantColorSchemeLabel} 
                        rqsRestaurantGenerateColorLabel={this.props.rqsRestaurantGenerateColorLabel} 
                        rqsRestaurantAddColorLabel={this.props.rqsRestaurantAddColorLabel} 
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel} 
                        rqsRestaurantSeatingChartLabel={this.props.rqsRestaurantSeatingChartLabel} 
                        rqsRestaurantSeatingChartYesGenerateLabel={this.props.rqsRestaurantSeatingChartYesGenerateLabel}
                        rqsRestaurantSeatingChartYesMakeLabel={this.props.rqsRestaurantSeatingChartYesMakeLabel} 
                        rqsRestaurantSeatingChartYesLaterLabel={this.props.rqsRestaurantSeatingChartYesLaterLabel} 
                        rqsRestaurantSeatingChartNoNeedLabel={this.props.rqsRestaurantSeatingChartNoNeedLabel} 
                        rqsRestaurantSeatingChartLeaveLabel={this.props.rqsRestaurantSeatingChartLeaveLabel} 
                        rqsRestaurantDeliveryOptionsLabel={this.props.rqsRestaurantDeliveryOptionsLabel} 
                        rqsRestaurantDineIn={this.props.rqsRestaurantDineIn} 
                        rqsRestaurantPickUpLabel={this.props.rqsRestaurantPickUpLabel}  
                        rqsRestaurantInHouseDeliveryLabel={this.props.rqsRestaurantInHouseDeliveryLabel}
                        rqsRestaurantThirdPartyDeliveryLabel={this.props.rqsRestaurantThirdPartyDeliveryLabel}  
                        rqsRestaurantThirdPartyDeliveryLeaveLabel={this.props.rqsRestaurantThirdPartyDeliveryLeaveLabel}
                    />}
                </div>
                
            )
        }
    }
    
export default RestaurantHours