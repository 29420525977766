import React from 'react';
import BuildMenu from './BuildMenu';
import UploadPhoto from './UploadPhoto';

class UploadMenuFromPhoto extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showBuildMenu: false, showUploadPhoto: false};
            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            if(event.target.value === "yes") {
                this.setState({value: event.target.value,
                    showUploadPhoto: true,
                    showBuildMenu: false });
            } else {
                this.setState({value: event.target.value,
                    showUploadPhoto: false,
                    showBuildMenu: true });
            }
        }

        render() {
            const { showBuildMenu, showUploadPhoto } = this.state;
            return (
                <div id="rqsMenuUploadFromPhotoItem">
                    <div className="rqsInverseDiv" onChange={this.handleChange}>
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsMenuUploadFromPhoto">{this.props.rqsMenuUploadFromPhotoLabel}</label>
                            <br />
                            <span className="yesNoSpan">
                                <input id="rqsMenuUploadFromPhoto" name="rqsMenuUploadFromPhoto" className="rqsInput rqsCheckbox checkbox" type="radio" value="yes" checked={this.state.value === "yes"}/>
                                <label className="choice" htmlFor="rqsMenuUploadFromPhoto">{this.props.rqsYes}</label>
                            </span>
                            <span className="yesNoSpan">
                                <input id="rqsMenuUploadFromPhoto" name="rqsMenuUploadFromPhoto" className="rqsInput rqsCheckbox checkbox" type="radio" value="no" checked={this.state.value === "no"}/>
                                <label className="choice" htmlFor="rqsMenuUploadFromPhoto">{this.props.rqsNo}</label>
                            </span>
                        </div>
                    </div>
                    {showUploadPhoto && <UploadPhoto 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo}
                        rqsIntegrateLabel={this.props.rqsIntegrateLabel}
                        rqsMenuUploadFromPhotoLabel={this.props.rqsMenuUploadFromPhotoLabel}
                        rqsMenuBuildLabel={this.props.rqsMenuBuildLabel}
                        rqsMenuNewItemLabel={this.props.rqsMenuNewItemLabel}
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel}
                    />}
                    {showBuildMenu && <BuildMenu 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo}
                        rqsIntegrateLabel={this.props.rqsIntegrateLabel}
                        rqsMenuUploadFromPhotoLabel={this.props.rqsMenuUploadFromPhotoLabel}
                        rqsMenuBuildLabel={this.props.rqsMenuBuildLabel}
                        rqsMenuNewItemLabel={this.props.rqsMenuNewItemLabel}
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel}
                    />}
                </div>
            )
        }
    }
    
export default UploadMenuFromPhoto