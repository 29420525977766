import React, { Component } from 'react';
import SplashPage from '../components/SplashPage';

class Home extends Component {
    render() {
        return (
            <div className="condiv home">
            <SplashPage/>
            </div>
            )
        }
    }
    
export default Home
    