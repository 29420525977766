import React from 'react';
import ContactPreference from './ContactPreference';

class RestaurantName extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showContactPreference: false
            };

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            this.setState({value: event.target.value,
                showContactPreference: true});
        }

        render() {
            const { showContactPreference } = this.state;
            return (
                <div id="rqsRestaurantNameItem">
                    <div className="rqsInverseDiv" onChange={this.handleChange}>
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsRestaurantName">{this.props.rqsRestaurantNameLabel}</label>
                            <br />
                            <input id="rqsRestaurantName" name="rqsRestaurantName" className="rqsInput rqsTextInput medium" type="text" maxLength="255" required/> 
                        </div>
                    </div>
                    {showContactPreference && <ContactPreference
                        rqsRestaurantNameLabel={this.props.rqsRestaurantNameLabel} 
                        rqsContactPreferenceLabel={this.props.rqsContactPreferenceLabel} 
                        rqsPhoneNumberLabel={this.props.rqsPhoneNumberLabel} 
                        rqsEmailLabel={this.props.rqsEmailLabel}
                        rqsEmailOptionLabel={this.props.rqsEmailOptionLabel} 
                        rqsPhoneOptionLabel={this.props.rqsPhoneOptionLabel} 
                    />}
                </div>
                
            )
        }
    }
    
export default RestaurantName