import React from 'react';
import EmailPreference from './EmailPreference';
import PhonePreference from './PhonePreference';

class ContactPreference extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showEmailPreference: false, showPhonePreference: false
            };

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            if(event.target.value === "email") {
                this.setState({value: event.target.value,
                    showEmailPreference: true,
                    showPhonePreference: false });
            } else {
                this.setState({value: event.target.value,
                    showEmailPreference: false,
                    showPhonePreference: true });
            }
            
        }

        render() {
            const { showEmailPreference, showPhonePreference } = this.state;
            return (
                <div id="rqsContactPreferenceItem">
                    <div className="rqsDiv">
                        <div className="rqsInputContainer radio" onChange={this.handleChange}>
                            <label className="rqsLabel" htmlFor="rqsContactPreference">{this.props.rqsContactPreferenceLabel}</label>
                                <br />
                                <span className="contactPreferenceSpan">
                                    <input id="rqsEmailContactPreference" name="rqsEmailContactPreference" className="rqsInput rqsCheckbox radio" type="radio" value="email" checked={this.state.value === "email"}/>
                                    <label className="choice" htmlFor="rqsEmailContactPreference">{this.props.rqsEmailOptionLabel}</label>
                                </span>
                                <span className="contactPreferenceSpan">
                                    <input id="rqsPhoneContactPreference" name="rqsPhoneContactPreference" className="rqsInput rqsCheckbox radio" type="radio" value="phone" checked={this.state.value === "phone"}/>
                                    <label className="choice" htmlFor="rqsPhoneContactPreference">{this.props.rqsPhoneOptionLabel}</label>
                                </span>
                        </div>
                    </div>
                    {showEmailPreference && <EmailPreference
                        rqsEmailLabel={this.props.rqsEmailLabel}
                        rqsEmailOptionLabel={this.props.rqsEmailOptionLabel} 
                    />}
                    {showPhonePreference && <PhonePreference
                        rqsPhoneNumberLabel={this.props.rqsPhoneNumberLabel}
                        rqsPhoneOptionLabel={this.props.rqsPhoneOptionLabel} 
                    />}
                </div>
                
            )
        }
    }
    
export default ContactPreference