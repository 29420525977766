import React from 'react';

class RestaurantCuisine extends React.Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        render() {
            return (
                <div id="restaurantHoursInputs">
                    <div>
                        <label className="dayNameLabel">Monday -</label><input type="checkbox" className="closed" value="closed"/>
                        <span>Closed</span><br />
                        <select className="rqsSelect fromH"  id="fromH" defaultValue="9">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect fromM"  id="fromM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect fromAP"  id="fromAP" defaultValue=" AM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>

                        <label className="rqsHoursTo"> to </label><br />
                        <select className="rqsSelect toH"  id="toH" defaultValue="5">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect toM"  id="toM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect toAP"  id="toAP" defaultValue=" PM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>
                        <label className="secondHourPadding"> </label>
                    </div>
                    <div>
                        <label className="dayNameLabel">Tuesday -</label><input type="checkbox" className="closed" value="closed"/>
                        <span>Closed</span><br />
                        <select className="rqsSelect fromH"  id="fromH" defaultValue="9">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect fromM"  id="fromM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect fromAP"  id="fromAP" defaultValue=" AM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>

                        <label className="rqsHoursTo"> to </label><br />
                        <select className="rqsSelect toH"  id="toH" defaultValue="5">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect toM"  id="toM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect toAP"  id="toAP" defaultValue=" PM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>
                        <label className="secondHourPadding"> </label>
                    </div>
                    <div>
                        <label className="dayNameLabel">Wednesday -</label><input type="checkbox" className="closed" value="closed"/>
                        <span>Closed</span><br />
                        <select className="rqsSelect fromH"  id="fromH" defaultValue="9">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect fromM"  id="fromM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect fromAP"  id="fromAP" defaultValue=" AM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>

                        <label className="rqsHoursTo"> to </label><br />
                        <select className="rqsSelect toH"  id="toH" defaultValue="5">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect toM"  id="toM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect toAP"  id="toAP" defaultValue=" PM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>
                        <label className="secondHourPadding"> </label>
                    </div>
                    <div>
                        <label className="dayNameLabel">Thursday -</label><input type="checkbox" className="closed" value="closed"/>
                        <span>Closed</span><br />
                        <select className="rqsSelect fromH"  id="fromH" defaultValue="9">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect fromM"  id="fromM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect fromAP"  id="fromAP" defaultValue=" AM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>

                        <label className="rqsHoursTo"> to </label><br />
                        <select className="rqsSelect toH"  id="toH" defaultValue="5">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect toM"  id="toM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect toAP"  id="toAP" defaultValue=" PM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>
                        <label className="secondHourPadding"> </label>
                    </div>
                    <div>
                        <label className="dayNameLabel">Friday -</label><input type="checkbox" className="closed" value="closed"/>
                        <span>Closed</span><br />
                        <select className="rqsSelect fromH"  id="fromH" defaultValue="9">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect fromM"  id="fromM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect fromAP"  id="fromAP" defaultValue=" AM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>

                        <label className="rqsHoursTo"> to </label><br />
                        <select className="rqsSelect toH"  id="toH" defaultValue="5">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect rqsSelect toM"  id="toM">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect rqsSelect toAP"  id="toAP">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>
                        <label className="secondHourPadding"> </label>
                    </div>
                    <div>
                        <label className="dayNameLabel">Saturday -</label><input type="checkbox" className="closed" value="closed"/>
                        <span>Closed</span><br />
                        <select className="rqsSelect fromH"  id="fromH" defaultValue="9">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect fromM"  id="fromM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect fromAP"  id="fromAP" defaultValue=" AM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>

                        <label className="rqsHoursTo"> to </label><br />
                        <select className="rqsSelect toH"  id="toH" defaultValue="5">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect toM"  id="toM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect toAP"  id="toAP" defaultValue=" PM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>
                        <label className="secondHourPadding"> </label>
                    </div>
                    <div>
                        <label className="dayNameLabel">Sunday -</label><input type="checkbox" className="closed" value="closed"/>
                        <span>Closed</span><br />
                        <select className="rqsSelect fromH"  id="fromH" defaultValue="9">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect fromM"  id="fromM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect fromAP"  id="fromAP" defaultValue=" AM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>

                        <label className="rqsHoursTo"> to </label><br />
                        <select className="rqsSelect toH"  id="toH" defaultValue="5">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <select className="rqsSelect toM"  id="toM" defaultValue=":00">
                            <option value=":00">:00</option>
                            <option value=":15">:15</option>
                            <option value=":30">:30</option>
                            <option value=":45">:45</option>
                        </select>
                        <select className="rqsSelect toAP"  id="toAP" defaultValue=" PM">
                            <option value=" AM">AM</option>
                            <option value=" PM">PM</option>
                        </select>
                        <label className="secondHourPadding"> </label>
                    </div>
                </div>
                
            )
        }
    }
    
export default RestaurantCuisine