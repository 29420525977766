import React from 'react';
import UploadMenuFromPhoto from './UploadMenuFromPhoto';
import Switch from 'react-switch';
import digitalPourItems from '../../../../../img/digitalPourItems.png';
import marketManItems from '../../../../../img/marketManItems.png';
import yellowDogItems from '../../../../../img/yellowDogItems.png';
import peachworkItems from '../../../../../img/peachworkItems.png';

class ThirdPartyIntegrateItem extends React.Component {
        constructor(props) {
            super(props);
            this.state = {showUploadMenuFromPhoto: false, dineInChecked: false, pickUpChecked: false, inHouseChecked: false, thirdPartyChecked: false};
            
            this.handleDineInChange = this.handleDineInChange.bind(this);
            this.handlePickUpChange = this.handlePickUpChange.bind(this);
            this.handleInHouseChange = this.handleInHouseChange.bind(this);
            this.handleThirdPartyChange = this.handleThirdPartyChange.bind(this);
        }

        handleDineInChange(checked) {
            this.setState({dineInChecked: checked, showUploadMenuFromPhoto: true});
        }

        handlePickUpChange(checked) {
            this.setState({pickUpChecked: checked, showUploadMenuFromPhoto: true});
        }
        
        handleInHouseChange(checked) {
            this.setState({inHouseChecked: checked, showUploadMenuFromPhoto: true});
        }

        handleThirdPartyChange(checked) {
            this.setState({thirdPartyChecked: checked, showUploadMenuFromPhoto: true});
        }

        render() {
            const { showUploadMenuFromPhoto } = this.state;
            return (
                <div id="rqsThirdPartyIntegrateItem">
                    <div className="rqsDiv" onChange={this.handleChange}>
                        <div className="rqsInputContainer">
                            <div>
                                <ul>
                                    <li>
                                        <div>
                                            <Switch onChange={this.handleDineInChange} checked={this.state.dineInChecked} id="rqsRestaurantDeliveryOptions" name="rqsRestaurantDeliveryOptions" />
                                            <span className="rqsSwitchLabel"><img src={digitalPourItems} alt="Square DigitalPour Icon"/></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <Switch onChange={this.handlePickUpChange} checked={this.state.pickUpChecked} id="rqsRestaurantDeliveryOptions" name="rqsRestaurantDeliveryOptions" />
                                            <span className="rqsSwitchLabel"><img src={marketManItems} alt="Square MarketMan Icon"/></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <Switch onChange={this.handleInHouseChange} checked={this.state.inHouseChecked} id="rqsRestaurantDeliveryOptions" name="rqsRestaurantDeliveryOptions" />
                                            <span className="rqsSwitchLabel"><img src={yellowDogItems} alt="Square YellowDog Icon"/></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <Switch onChange={this.handleThirdPartyChange} checked={this.state.thirdPartyChecked} id="rqsRestaurantDeliveryOptions" name="rqsRestaurantDeliveryOptions" />
                                            <span className="rqsSwitchLabel"><img src={peachworkItems} alt="Square PeachWork Icon"/></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {showUploadMenuFromPhoto && <UploadMenuFromPhoto 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo}
                        rqsMenuUploadFromPhotoLabel={this.props.rqsMenuUploadFromPhotoLabel}
                        rqsMenuBuildLabel={this.props.rqsMenuBuildLabel}
                        rqsMenuNewItemLabel={this.props.rqsMenuNewItemLabel}
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel}
                    />}
                </div>
            )
        }
    }
    
export default ThirdPartyIntegrateItem