import React from 'react';

class PhonePreference extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showSubmit: false};

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            this.setState({value: event.target.value, showSubmit: true});
        }

        maxLengthCheck = (object) => {
            if (object.target.value.length > object.target.maxLength) {
                object.target.value = object.target.value.slice(0, object.target.maxLength)
            }
        }

        render() {
            const { showSubmit } = this.state;
            return (
                <div id="rqsPhoneNumberItem">
                    <div className="rqsInverseDiv">
                            <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsPhoneNumber">{this.props.rqsPhoneNumberLabel}</label>
                            <br />
                            <span className="phoneNumberSpan">
                                <input id="rqsPhoneNumber" name="rqsPhoneNumber" className="rqsInput rqsNumberInput" size="9" maxLength="9" onInput={this.maxLengthCheck} onChange={this.handleChange} placeholder="(###) ###-###" type="number"/>
                            </span>
                        </div>
                    </div>
                    <br />
                    {showSubmit && <input id="saveForm"  className="rqsSubmit" type="submit" name="submit" value="Looks good" />}
                </div>
                
            )
        }
    }
    
export default PhonePreference