import React from 'react';
import HighlightCard from './HighlightCard';
import { useTranslation } from "react-i18next";
import { MDBIcon } from 'mdbreact';
import { Link } from 'react-router-dom';
import SentryExperience from './SentryExperience';
import FHKingExperience from './FHKingExperience';
import CuisineExperience from './CuisineExperience';

function Experience() {
    const { t } = useTranslation();

    return (
        <div className="condiv">
            <div className="workExperience">
                <h1 className="subtopic"><strong>{t('workTitle.label')}</strong></h1>
                <h5 className="subtopic">{t('experienceUseExplanation.label')}</h5>
                <SentryExperience />
                <FHKingExperience />
                <CuisineExperience />
            </div>
            <div className="educationExperience">
                <h1 className="subtopic"><strong>{t('educationTitle.label')}</strong></h1>
                <div><HighlightCard className="educationCard" title={t('universityDegree.label')} where={t('university.label')} from={t('universityStartDate.label')} to={t('universityEndDate.label')}/></div>
                <div><HighlightCard className="educationCard" title={t('highSchoolDegree.label')} where={t('highSchool.label')} from={t('highSchoolStartDate.label')} to={t('highSchoolEndDate.label')}/></div>
            </div>
            <Link to="/skills" className="downButton"><MDBIcon icon="arrow-circle-down" size="4x" /></Link>
        </div>
    )
}
    
export default Experience
    