import React from 'react';
import RestaurantDeliveryOptions from './RestaurantDeliveryOptions';
import RestaurantSeatingChartUpload from './RestaurantSeatingChartUpload';
import RestaurantMakeSeatingChart from './RestaurantMakeSeatingChart';
class RestaurantSeatingChart extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showRestaurantDeliveryOptions: false, showPhotoUpload: false, showMakeSeatingChart: false
            };

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            if(event.target.value === "yesGenerate") {
                this.setState({value: event.target.value,
                    showRestaurantDeliveryOptions: false,
                    showPhotoUpload: true,
                    showMakeSeatingChart: false});
            } else if(event.target.value === "yesMake") {
                this.setState({value: event.target.value,
                    showRestaurantDeliveryOptions: false,
                    showPhotoUpload: false,
                    showMakeSeatingChart: true});
            } else {
                this.setState({value: event.target.value,
                    showRestaurantDeliveryOptions: true,
                    showPhotoUpload: false,
                    showMakeSeatingChart: false});
            }
            
        }

        render() {
            const { showRestaurantDeliveryOptions, showPhotoUpload, showMakeSeatingChart } = this.state;
            return (
                <div id="rqsRestaurantSeatingChartItem">
                    <div className="rqsDiv" onChange={this.handleChange}>
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsRestaurantSeatingChart">Does {this.props.restaurantNameInput} {this.props.rqsRestaurantSeatingChartLabel}</label>
                            <div className="radio">
                                <ul>
                                <li>
                                    <input id="rqsRestaurantSeatingChart" name="rqsRestaurantSeatingChart" className="rqsInput rqsCheckbox checkbox" type="radio" value="yesGenerate" checked={this.state.value === "yesGenerate"}/>
                                    <label className="choice" htmlFor="rqsRestaurantSeatingChart"> {this.props.rqsRestaurantSeatingChartYesGenerateLabel}</label>
                                </li>
                                <li>
                                    <input id="rqsRestaurantSeatingChart" name="rqsRestaurantSeatingChart" className="rqsInput rqsCheckbox checkbox" type="radio" value="yesMake" checked={this.state.value === "yesMake"}/>
                                    <label className="choice" htmlFor="rqsRestaurantSeatingChart"> {this.props.rqsRestaurantSeatingChartYesMakeLabel}</label>
                                </li>
                                <li>
                                    <input id="rqsRestaurantSeatingChart" name="rqsRestaurantSeatingChart" className="rqsInput rqsCheckbox checkbox" type="radio" value="later" checked={this.state.value === "later"} />
                                    <label className="choice" htmlFor="rqsRestaurantSeatingChart">I'll deal with it later</label>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {showRestaurantDeliveryOptions && <RestaurantDeliveryOptions
                        restaurantNameInput={this.props.restaurantNameInput}
                        rqsRestaurantCuisineLabel={this.props.rqsRestaurantCuisineLabel} 
                        rqsRestaurantHoursLabel={this.props.rqsRestaurantHoursLabel} 
                        rqsRestaurantLogoLabel={this.props.rqsRestaurantLogoLabel} 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo} 
                        rqsSkipLabel={this.props.rqsSkipLabel} 
                        rqsRestaurantColorSchemeLabel={this.props.rqsRestaurantColorSchemeLabel} 
                        rqsRestaurantGenerateColorLabel={this.props.rqsRestaurantGenerateColorLabel} 
                        rqsRestaurantAddColorLabel={this.props.rqsRestaurantAddColorLabel} 
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel} 
                        rqsRestaurantSeatingChartLabel={this.props.rqsRestaurantSeatingChartLabel} 
                        rqsRestaurantSeatingChartYesGenerateLabel={this.props.rqsRestaurantSeatingChartYesGenerateLabel}
                        rqsRestaurantSeatingChartYesMakeLabel={this.props.rqsRestaurantSeatingChartYesMakeLabel} 
                        rqsRestaurantSeatingChartYesLaterLabel={this.props.rqsRestaurantSeatingChartYesLaterLabel} 
                        rqsRestaurantSeatingChartNoNeedLabel={this.props.rqsRestaurantSeatingChartNoNeedLabel} 
                        rqsRestaurantSeatingChartLeaveLabel={this.props.rqsRestaurantSeatingChartLeaveLabel} 
                        rqsRestaurantDeliveryOptionsLabel={this.props.rqsRestaurantDeliveryOptionsLabel} 
                        rqsRestaurantDineIn={this.props.rqsRestaurantDineIn} 
                        rqsRestaurantPickUpLabel={this.props.rqsRestaurantPickUpLabel}  
                        rqsRestaurantInHouseDeliveryLabel={this.props.rqsRestaurantInHouseDeliveryLabel}
                        rqsRestaurantThirdPartyDeliveryLabel={this.props.rqsRestaurantThirdPartyDeliveryLabel}  
                        rqsRestaurantThirdPartyDeliveryLeaveLabel={this.props.rqsRestaurantThirdPartyDeliveryLeaveLabel}
                    />}
                    {showPhotoUpload && <RestaurantSeatingChartUpload
                        rqsRestaurantName={this.props.rqsRestaurantName}
                        rqsRestaurantCuisineLabel={this.props.rqsRestaurantCuisineLabel} 
                        rqsRestaurantHoursLabel={this.props.rqsRestaurantHoursLabel} 
                        rqsRestaurantLogoLabel={this.props.rqsRestaurantLogoLabel} 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo} 
                        rqsSkipLabel={this.props.rqsSkipLabel} 
                        rqsRestaurantColorSchemeLabel={this.props.rqsRestaurantColorSchemeLabel} 
                        rqsRestaurantGenerateColorLabel={this.props.rqsRestaurantGenerateColorLabel} 
                        rqsRestaurantAddColorLabel={this.props.rqsRestaurantAddColorLabel} 
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel} 
                        rqsRestaurantSeatingChartLabel={this.props.rqsRestaurantSeatingChartLabel} 
                        rqsRestaurantSeatingChartYesGenerateLabel={this.props.rqsRestaurantSeatingChartYesGenerateLabel}
                        rqsRestaurantSeatingChartYesMakeLabel={this.props.rqsRestaurantSeatingChartYesMakeLabel} 
                        rqsRestaurantSeatingChartYesLaterLabel={this.props.rqsRestaurantSeatingChartYesLaterLabel} 
                        rqsRestaurantSeatingChartNoNeedLabel={this.props.rqsRestaurantSeatingChartNoNeedLabel} 
                        rqsRestaurantSeatingChartLeaveLabel={this.props.rqsRestaurantSeatingChartLeaveLabel} 
                        rqsRestaurantDeliveryOptionsLabel={this.props.rqsRestaurantDeliveryOptionsLabel} 
                        rqsRestaurantDineIn={this.props.rqsRestaurantDineIn} 
                        rqsRestaurantPickUpLabel={this.props.rqsRestaurantPickUpLabel}  
                        rqsRestaurantInHouseDeliveryLabel={this.props.rqsRestaurantInHouseDeliveryLabel}
                        rqsRestaurantThirdPartyDeliveryLabel={this.props.rqsRestaurantThirdPartyDeliveryLabel}  
                        rqsRestaurantThirdPartyDeliveryLeaveLabel={this.props.rqsRestaurantThirdPartyDeliveryLeaveLabel}
                    />}
                    {showMakeSeatingChart && <RestaurantMakeSeatingChart
                        rqsRestaurantName={this.props.rqsRestaurantName}
                        rqsRestaurantCuisineLabel={this.props.rqsRestaurantCuisineLabel} 
                        rqsRestaurantHoursLabel={this.props.rqsRestaurantHoursLabel} 
                        rqsRestaurantLogoLabel={this.props.rqsRestaurantLogoLabel} 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo} 
                        rqsSkipLabel={this.props.rqsSkipLabel} 
                        rqsRestaurantColorSchemeLabel={this.props.rqsRestaurantColorSchemeLabel} 
                        rqsRestaurantGenerateColorLabel={this.props.rqsRestaurantGenerateColorLabel} 
                        rqsRestaurantAddColorLabel={this.props.rqsRestaurantAddColorLabel} 
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel} 
                        rqsRestaurantSeatingChartLabel={this.props.rqsRestaurantSeatingChartLabel} 
                        rqsRestaurantSeatingChartYesGenerateLabel={this.props.rqsRestaurantSeatingChartYesGenerateLabel}
                        rqsRestaurantSeatingChartYesMakeLabel={this.props.rqsRestaurantSeatingChartYesMakeLabel} 
                        rqsRestaurantSeatingChartYesLaterLabel={this.props.rqsRestaurantSeatingChartYesLaterLabel} 
                        rqsRestaurantSeatingChartNoNeedLabel={this.props.rqsRestaurantSeatingChartNoNeedLabel} 
                        rqsRestaurantSeatingChartLeaveLabel={this.props.rqsRestaurantSeatingChartLeaveLabel} 
                        rqsRestaurantDeliveryOptionsLabel={this.props.rqsRestaurantDeliveryOptionsLabel} 
                        rqsRestaurantDineIn={this.props.rqsRestaurantDineIn} 
                        rqsRestaurantPickUpLabel={this.props.rqsRestaurantPickUpLabel}  
                        rqsRestaurantInHouseDeliveryLabel={this.props.rqsRestaurantInHouseDeliveryLabel}
                        rqsRestaurantThirdPartyDeliveryLabel={this.props.rqsRestaurantThirdPartyDeliveryLabel}  
                        rqsRestaurantThirdPartyDeliveryLeaveLabel={this.props.rqsRestaurantThirdPartyDeliveryLeaveLabel}
                    />}
                </div>
                
            )
        }
    }
    
export default RestaurantSeatingChart