import React from 'react';
import RestaurantWebsite from './RestaurantWebsite';
import Switch from 'react-switch';

class RestaurantDeliveryOptions extends React.Component {
        constructor(props) {
            super(props);
            this.state = {showRestaurantWebsite: false, dineInChecked: false, pickUpChecked: false, inHouseChecked: false, thirdPartyChecked: false
            };

            this.handleDineInChange = this.handleDineInChange.bind(this);
            this.handlePickUpChange = this.handlePickUpChange.bind(this);
            this.handleInHouseChange = this.handleInHouseChange.bind(this);
            this.handleThirdPartyChange = this.handleThirdPartyChange.bind(this);
        }

        handleDineInChange(checked) {
            this.setState({dineInChecked: checked, showRestaurantWebsite: true});
        }

        handlePickUpChange(checked) {
            this.setState({pickUpChecked: checked, showRestaurantWebsite: true});
        }
        
        handleInHouseChange(checked) {
            this.setState({inHouseChecked: checked, showRestaurantWebsite: true});
        }

        handleThirdPartyChange(checked) {
            this.setState({thirdPartyChecked: checked, showRestaurantWebsite: true});
        }

        render() {
            const { showRestaurantWebsite } = this.state;
            return (
                <div id="rqsRestaurantDeliveryOptionsItem">
                    <div className="rqsDiv">
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsRestaurantDeliveryOptions">{this.props.rqsRestaurantDeliveryOptionsLabel}</label>
                            <div>
                                <ul>
                                    <li>
                                        <div>
                                            <Switch onChange={this.handleDineInChange} checked={this.state.dineInChecked} id="rqsRestaurantDeliveryOptions" name="rqsRestaurantDeliveryOptions" />
                                            <span className="rqsSwitchLabel">{this.props.rqsRestaurantDineIn}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <Switch onChange={this.handlePickUpChange} checked={this.state.pickUpChecked} id="rqsRestaurantDeliveryOptions" name="rqsRestaurantDeliveryOptions" />
                                            <span className="rqsSwitchLabel">{this.props.rqsRestaurantPickUpLabel}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <Switch onChange={this.handleInHouseChange} checked={this.state.inHouseChecked} id="rqsRestaurantDeliveryOptions" name="rqsRestaurantDeliveryOptions" />
                                            <span className="rqsSwitchLabel">{this.props.rqsRestaurantInHouseDeliveryLabel}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <Switch onChange={this.handleThirdPartyChange} checked={this.state.thirdPartyChecked} id="rqsRestaurantDeliveryOptions" name="rqsRestaurantDeliveryOptions" />
                                            <span className="rqsSwitchLabel">{this.props.rqsRestaurantThirdPartyDeliveryLabel}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {showRestaurantWebsite && <RestaurantWebsite />}
                </div>
                
            )
        }
    }
    
export default RestaurantDeliveryOptions