import React from 'react';
import squareHome from '../../../img/squareHome.png';
import squareTeam from '../../../img/squareTeam.png';
import squareOrders from '../../../img/squareOrders.png';
import squareMenus from '../../../img/squareMenus.png';
class RQScomplete extends React.Component {
    render() {    
        return (
                <div className="rqsComplete">
                    <h2><strong>Let's go over what we set up</strong></h2>
                    <ul>
                        <li>
                            <img src={squareHome} alt="Square Home Icon"/>You got your restaurant all set up and established yourself as the owner
                        </li>
                        <li>
                            <img src={squareMenus} alt="Square Menu Icon"/>Your menu is all set up and is synced across your POS Device and Website, waiting for orders everywhere
                        </li>
                        <li>
                            <img src={squareTeam} alt="Square Team Icon"/>You got your team set up and ready to start producing orders
                        </li>
                        <li>
                            <img src={squareOrders} alt="Square Orders Icon"/>Your restaurant is set to appear on Google Maps and has an auto generated website that you can edit with Square Online any time you'd like
                        </li>
                    </ul>
                    
                </div>
            )
        }
        
    }
    
export default RQScomplete
    