import React, {useState} from 'react';
import HighlightCard from './HighlightCard';
import { useTranslation } from "react-i18next";
import { Modal, Button, Image } from 'react-bootstrap';
import cuisineGroupPhoto from '../img/cuisine_group_pbnj.jpg';

function CuisineExperience() {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="sentryExperience">
            <button className="experienceButton" onClick={handleShow}><HighlightCard title={t('cuisineTitle.label')} where={t('cuisine.label')} from={t('cuisineStart.label')} to={t('cuisineEnd.label')}/></button>
            
            <Modal size="lg" show={show} onHide={handleClose}>
                <div className="modalcondiv">
                <Modal.Header closeButton>
                <Modal.Title><h2>{t('cuisine.label')} - {t('cuisineTitle.label')}</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image onClick={()=> window.open(cuisineGroupPhoto, "_blank")} fluid src={cuisineGroupPhoto} alt="WUD Cuisine Group Photo"/>
                    <p>{t('cuisineDescription1.label')}</p>
                    <p>{t('cuisineDescription2.label')}</p>
                    <p>{t('cuisineDescription3.label')}<a className="resumeLink" href="https://willhofkamp.github.io/folder/WilliamHofkampResume.pdf"><u>{t('here.label')}</u></a></p>
                </Modal.Body>
                <Modal.Footer>
                <Button block className="closeModalButton" variant="outline-dark" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
                </div>
            </Modal>
        </div>
    )
}
    
export default CuisineExperience