import { MDBIcon } from 'mdbreact';
import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

function Social() {
        const { t } = useTranslation();
        return (
            <div className="condiv">
                <h1 className="subtopic"><strong>{t('aboutMeTitle.label')}</strong></h1>
                <h4>{t('greeting.label')}</h4>
                <h1>{t('intro.label')}</h1>
                <h3>{t('jobtitle.label')}</h3>
                <br/>
                <a className="resumeLink" href="https://willhofkamp.github.io/folder/WilliamHofkampResume.pdf"><h4><u>{t('resumelinkText.label')}</u></h4></a>
                <br/>
                <p>{t('shortbio1.label')}</p>
                <p>{t('shortbio2.label')}</p>
                <p>{t('shortbio3.label')}</p>
                <p>{t('shortbio4.label')}</p>
                <Link to="/experience" className="downButton"><MDBIcon icon="arrow-circle-down" size="4x"/></Link>
            </div>
        )
        
    }
    
export default Social
    