import React from 'react';

class EmailPreference extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showSubmit: false};

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            this.setState({value: event.target.value, showSubmit: true});
        }

        render() {
            const { showSubmit } = this.state;
            return (
                <div id="rqsEmailItem">
                    <div className="rqsInverseDiv">
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsEmail">{this.props.rqsEmailLabel}</label>
                            <br/>
                            <input id="rqsEmail" name="rqsEmail" className="rqsInput rqsTextInput medium" type="text" maxLength="255" onChange={this.handleChange} /> 
                        </div>
                    </div>
                    <br />
                    {showSubmit && <input id="saveForm"  className="rqsSubmit" type="submit" name="submit" value="Looks good" />}
                
                </div>
                
            )
        }
    }
    
export default EmailPreference