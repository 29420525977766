import React from 'react';
import FirstLastName from '../RQS/RQSComponents/Landing/FirstLastName';
import RestaurantInfoContainer from '../RQS/RQSComponents/RestaurantInfo/RestaurantInfoContainer';
class RQSlanding extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showRestaurantInfo: false };
        
            this.handleSubmit = this.handleSubmit.bind(this);
        }
        
        handleSubmit(event) {
            event.preventDefault();
            var eventIterator = 0;
            var updatedState = {}
            updatedState.showRestaurantInfo = true;

            var name = "";
            var value = "";
            while (event.target[eventIterator] != null) {
                name = event.target[eventIterator].name;
                value = event.target[eventIterator].value;
                updatedState[name] = value; 
                eventIterator++;
            }
            this.setState(updatedState);
        }

        render() {
            const { showRestaurantInfo } = this.state;
            return (
                <div className="rqsLanding">
                    <h2><strong>Let's start at Square one</strong></h2>
                    <form id="landingForm" className="rqsForm" onSubmit={this.handleSubmit}>				
                            <FirstLastName
                                rqsNameLabel={this.props.rqsNameLabel} 
                                rqsRestaurantNameLabel={this.props.rqsRestaurantNameLabel} 
                                rqsContactPreferenceLabel={this.props.rqsContactPreferenceLabel} 
                                rqsPhoneNumberLabel={this.props.rqsPhoneNumberLabel} 
                                rqsEmailLabel={this.props.rqsEmailLabel} 
                                rqsFirstNameLabel={this.props.rqsFirstNameLabel} 
                                rqsLastNameLabel={this.props.rqsLastNameLabel} 
                                rqsEmailOptionLabel={this.props.rqsEmailOptionLabel} 
                                rqsPhoneOptionLabel={this.props.rqsPhoneOptionLabel} 
                            />

                    </form>
                    
                    {showRestaurantInfo && <RestaurantInfoContainer restaurantNameInput={this.state.rqsRestaurantName}/>}	
                </div>
            )
        }
    }
    
export default RQSlanding