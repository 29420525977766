import React from 'react';
import RestaurantHours from './RestaurantHours';
import CreatableSelect from 'react-select/creatable';
import cuisinesList from '../../../../../json/cuisines.json';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

class RestaurantCuisine extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showRestaurantHours: false
            };

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            this.setState({showRestaurantHours: true});
        }

        render() {
            const { showRestaurantHours } = this.state;
            return (
                <div id="rqsRestaurantCuisineItem">
                    <div className="rqsDiv" >
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsRestaurantCuisine">{this.props.rqsRestaurantCuisineLabel} {this.props.restaurantNameInput}?</label>
                            <div>
                                <CreatableSelect closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options = {cuisinesList}
                                        id="rqsRestaurantCuisine" 
                                        name="rqsRestaurantCuisine" 
                                        className="rqsInput rqsSelect"
                                        onChange={this.handleChange}
                                        required
                                    />
                            </div>
                        </div>
                    </div>
                    {showRestaurantHours && <RestaurantHours
                        restaurantNameInput={this.props.restaurantNameInput}
                        rqsRestaurantCuisineLabel={this.props.rqsRestaurantCuisineLabel} 
                        rqsRestaurantHoursLabel={this.props.rqsRestaurantHoursLabel} 
                        rqsRestaurantLogoLabel={this.props.rqsRestaurantLogoLabel} 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo} 
                        rqsSkipLabel={this.props.rqsSkipLabel} 
                        rqsRestaurantColorSchemeLabel={this.props.rqsRestaurantColorSchemeLabel} 
                        rqsRestaurantGenerateColorLabel={this.props.rqsRestaurantGenerateColorLabel} 
                        rqsRestaurantAddColorLabel={this.props.rqsRestaurantAddColorLabel} 
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel} 
                        rqsRestaurantSeatingChartLabel={this.props.rqsRestaurantSeatingChartLabel} 
                        rqsRestaurantSeatingChartYesGenerateLabel={this.props.rqsRestaurantSeatingChartYesGenerateLabel}
                        rqsRestaurantSeatingChartYesMakeLabel={this.props.rqsRestaurantSeatingChartYesMakeLabel} 
                        rqsRestaurantSeatingChartYesLaterLabel={this.props.rqsRestaurantSeatingChartYesLaterLabel} 
                        rqsRestaurantSeatingChartNoNeedLabel={this.props.rqsRestaurantSeatingChartNoNeedLabel} 
                        rqsRestaurantSeatingChartLeaveLabel={this.props.rqsRestaurantSeatingChartLeaveLabel} 
                        rqsRestaurantDeliveryOptionsLabel={this.props.rqsRestaurantDeliveryOptionsLabel} 
                        rqsRestaurantDineIn={this.props.rqsRestaurantDineIn} 
                        rqsRestaurantPickUpLabel={this.props.rqsRestaurantPickUpLabel}  
                        rqsRestaurantInHouseDeliveryLabel={this.props.rqsRestaurantInHouseDeliveryLabel}
                        rqsRestaurantThirdPartyDeliveryLabel={this.props.rqsRestaurantThirdPartyDeliveryLabel}  
                        rqsRestaurantThirdPartyDeliveryLeaveLabel={this.props.rqsRestaurantThirdPartyDeliveryLeaveLabel}
                    />}
                </div>
                
            )
        }
    }
    
export default RestaurantCuisine