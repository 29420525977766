import React from 'react';
import ThirdPartyMenuItem from './RQSComponents/Menu/ThirdPartyMenuItem';
import RQSstaff from './RQSstaff';
class RQSmenu extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showStaff: false};
            this.handleChange = this.handleChange.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
        }

        handleChange(event) {
            this.setState({value: event.target.value});
        }
        
        handleSubmit(event) {
            event.preventDefault();
            this.setState({showStaff: true});
        }

        render() {
            const { showStaff } = this.state;
            return (
                <div className="rqsMenu">
                    <h2><strong>Whats on the menu?</strong></h2>
                    <form id="menuForm" className="rqsForm" onSubmit={this.handleSubmit}>
                            <ThirdPartyMenuItem
                                rqsYes={this.props.rqsYes} 
                                rqsNo={this.props.rqsNo}
                                rqsThirdPartyLabel={this.props.rqsThirdPartyLabel}
                                rqsIntegrateLabel={this.props.rqsIntegrateLabel}
                                rqsMenuUploadFromPhotoLabel={this.props.rqsMenuUploadFromPhotoLabel}
                                rqsMenuBuildLabel={this.props.rqsMenuBuildLabel}
                                rqsMenuNewItemLabel={this.props.rqsMenuNewItemLabel}
                                rqsLooksGoodLabel={this.props.rqsLooksGoodLabel}
                            />
                            <input id="saveForm"  className="rqsSubmit" type="submit" name="submit" value="Looks good" />
                    </form>

                    {showStaff && <RQSstaff />}
                </div>
            )
        }
    }
    
export default RQSmenu