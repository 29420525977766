import React from 'react';
import RestaurantType from './RestaurantType';

class RestaurantLocation extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showRestaurantType: false
            };

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            this.setState({value: event.target.value,
                showRestaurantType: true});
        }

        render() {
            const { showRestaurantType } = this.state;
            return (
                <div id="rqsRestaurantLocationItem">
                    <div className="rqsDiv" onChange={this.handleChange}>
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsRestaurantLocation">{this.props.rqsRestaurantLocationLabel} {this.props.rqsRestaurantName} located?</label>
                            <br />
                            <input id="rqsRestaurantLocation" name="rqsRestaurantLocation" className="rqsInput rqsTextInput medium" type="text" maxLength="255" required/>                             
                        </div>
                    </div>
                    {showRestaurantType && <RestaurantType
                        restaurantNameInput={this.props.rqsRestaurantName}
                        rqsRestaurantTypeLabel={this.props.rqsRestaurantTypeLabel} 
                        rqsRestaurantCuisineLabel={this.props.rqsRestaurantCuisineLabel} 
                        rqsRestaurantHoursLabel={this.props.rqsRestaurantHoursLabel} 
                        rqsRestaurantLogoLabel={this.props.rqsRestaurantLogoLabel} 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo} 
                        rqsSkipLabel={this.props.rqsSkipLabel} 
                        rqsRestaurantColorSchemeLabel={this.props.rqsRestaurantColorSchemeLabel} 
                        rqsRestaurantGenerateColorLabel={this.props.rqsRestaurantGenerateColorLabel} 
                        rqsRestaurantAddColorLabel={this.props.rqsRestaurantAddColorLabel} 
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel} 
                        rqsRestaurantSeatingChartLabel={this.props.rqsRestaurantSeatingChartLabel} 
                        rqsRestaurantSeatingChartYesGenerateLabel={this.props.rqsRestaurantSeatingChartYesGenerateLabel}
                        rqsRestaurantSeatingChartYesMakeLabel={this.props.rqsRestaurantSeatingChartYesMakeLabel} 
                        rqsRestaurantSeatingChartYesLaterLabel={this.props.rqsRestaurantSeatingChartYesLaterLabel} 
                        rqsRestaurantSeatingChartNoNeedLabel={this.props.rqsRestaurantSeatingChartNoNeedLabel} 
                        rqsRestaurantSeatingChartLeaveLabel={this.props.rqsRestaurantSeatingChartLeaveLabel} 
                        rqsRestaurantDeliveryOptionsLabel={this.props.rqsRestaurantDeliveryOptionsLabel} 
                        rqsRestaurantDineIn={this.props.rqsRestaurantDineIn} 
                        rqsRestaurantPickUpLabel={this.props.rqsRestaurantPickUpLabel}  
                        rqsRestaurantInHouseDeliveryLabel={this.props.rqsRestaurantInHouseDeliveryLabel}
                        rqsRestaurantThirdPartyDeliveryLabel={this.props.rqsRestaurantThirdPartyDeliveryLabel}  
                        rqsRestaurantThirdPartyDeliveryLeaveLabel={this.props.rqsRestaurantThirdPartyDeliveryLeaveLabel}
                    />}
                </div>
                
            )
        }
    }
    
export default RestaurantLocation