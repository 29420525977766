import React from 'react';

class RestaurantWebsite extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showSubmit: false, showWebsiteUrl: false
            };

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            event.preventDefault();
            if(event.target.value === "yes") {
                this.setState({value: event.target.value,
                    showWebsiteUrl: true,
                    showSubmit: true});
            } else {
                this.setState({value: event.target.value,
                    showWebsiteUrl: false,
                    showSubmit: true});
            }
            
        }

        render() {
            const { showSubmit, showWebsiteUrl } = this.state;
            return (
                <div id="rqsRestaurantWebsiteItem">
                    <div className="rqsInverseDiv" onChange={this.handleChange}>
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsRestaurantWebsite">Does your restaurant have a website?</label>
                            <div className="radio">
                                <ul>
                                <li>
                                    <input id="rqsRestaurantWebsite" name="rqsRestaurantWebsite" className="rqsInput rqsCheckbox radio" type="radio" value="yes" checked={this.state.value === "yes"}/>
                                    <label className="radio" htmlFor="rqsRestaurantWebsite">Yes</label>
                                </li>
                                <li>
                                    <input id="rqsRestaurantWebsite" name="rqsRestaurantWebsite" className="rqsInput rqsCheckbox radio" type="radio" value="noGenerate" checked={this.state.value === "noGenerate"}/>
                                    <label className="radio" htmlFor="rqsRestaurantWebsite">No, generate one for me</label>
                                </li>
                                <li>
                                    <input id="rqsRestaurantWebsite" name="rqsRestaurantWebsite" className="rqsInput rqsCheckbox radio" type="radio" value="noLater" checked={this.state.value === "noLater"} />
                                    <label className="radio" htmlFor="rqsRestaurantWebsite"> No, I'll do it myself later</label>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {showWebsiteUrl && <div className="rqsDiv">
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsRestaurantWebsiteUrl">{this.props.rqsRestaurantNameLabel}</label>
                            <br />
                            <input id="rqsRestaurantWebsiteUrl" name="rqsRestaurantWebsiteUrl" className="rqsInput rqsTextInput medium" type="text" placeholder="Your website URL" maxLength="255"/> 
                        </div>
                    </div>}
                    {showSubmit && <input id="saveForm"  className="rqsSubmit" type="submit" name="submit" value="Looks good" />}
                </div>
                
            )
        }
    }
    
export default RestaurantWebsite