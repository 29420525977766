import { MDBIcon } from 'mdbreact';
import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

function SquareProposal() {
        const { t } = useTranslation();
        return (
            <div className="condiv">
                <h1 className="subtopic"><strong>{t('squareProposalTitle.label')}</strong></h1>
                <h2>{t('squareProposalSub.label')}</h2>
                <p>{t('squareProposal1.label')}</p>
                <a href="https://drive.google.com/file/d/1uJDBNztS9OY_r8YAVAXyHvbkrm7BET-Q/view?usp=sharing" className="downButton">Check out the Design Doc<MDBIcon icon="arrow-circle-right" size="2x"/></a>
                <br />
                <p>{t('squareProposal2.label')} The faster and easier the user is intertwined with Square’s services, the less likely they will go with competing products and services.</p>
                <Link to="/rqs" className="downButton">{t('rqsLink.label')} Demo<MDBIcon icon="arrow-circle-right" size="2x"/></Link>
                <br />                                                 
                <Link to="/" className="downButton">{t('goHome.label')}<MDBIcon icon="arrow-circle-down" size="4x"/></Link>
            </div>
        )
        
    }
    
export default SquareProposal
    