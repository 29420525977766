import React from 'react';
import { MDBIcon } from 'mdbreact';
export default class DynamicTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      items: []
    }
  }

  updateMessage(event) {
    this.setState({
      message: event.target.value
    });
  }

  handleClick() {
    var items = this.state.items;
    var newEmployee = this.state.message.split(", ");
    items.push(newEmployee);

    this.setState({
      items: items,
      message: ""
    });
  }

  handleItemDeleted(i) {
    var items = this.state.items;

    items.splice(i, 1);

    this.setState({
      items: items
    });
  }

  renderRows() {
    var context = this;

    return  this.state.items.map(function(o, i) {
              return (
                <tr key={"item-" + i}>
                  <td>
                    {o[0]}
                  </td>
                  <td>
                    {o[1]}
                  </td>
                  <td>
                    Pending
                  </td>
                  <td>
                    <button className="rqsDeleteButton"
                      onClick={context.handleItemDeleted.bind(context, i)}
                    >
                      <MDBIcon icon="trash-alt" />
                    </button>
                  </td>
                </tr>
              );
            });
  }

  render() {
    return (
      <div className="rqsForm rqsDiv rqsTable">
        <div className="staffTableContainer">
            <table >
            <thead>
                <tr>
                <th>
                    Name
                </th>
                <th>
                    Job
                </th>
                <th>
                    Status
                </th>
                <th>

                </th>
                </tr>
                <tr>
                <td>
                  You!
                </td>
                <td>
                  Owner
                </td>
                <td>
                  Pending
                </td>
                </tr>
            </thead>
            <tbody>
                {this.renderRows()}
            </tbody>
            </table>
        </div>
        <br/>
        <br/>
        <input
          type="text"
          value={this.state.message}
          onChange={this.updateMessage.bind(this)}
        />
        <button
          onClick={this.handleClick.bind(this)}
          placeholder="Name, Title"
        ><MDBIcon icon="plus-square" /></button>
      </div>
    );
  }
}