import React from 'react';
import RestaurantName from './RestaurantName';

class FirstLastName extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showRestaurantName: false
            };

            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            this.setState({value: event.target.value,
                showRestaurantName: true});
        }

        render() {
            const { showRestaurantName } = this.state;
            return (
                <div id="rqsNameItem">
                    <div className="rqsDiv">
                            <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsFirstName">{this.props.rqsNameLabel}</label>
                            <br/>
                            <span className="nameSpan">
                                <input id="rqsFirstName" name= "rqsFirstName" className="rqsInput rqsTextInput" maxLength="255" size="20" placeholder={this.props.rqsFirstNameLabel} required/>
                            </span>
                            <span className="nameSpan">
                                <input onChange={this.handleChange} id="rqsLastName" name= "rqsLastName" className="rqsInput rqsTextInput" maxLength="255" size="20" placeholder={this.props.rqsLastNameLabel} required/>
                            </span> 
                        </div>
                    </div>
                    {showRestaurantName && <RestaurantName
                        rqsRestaurantNameLabel={this.props.rqsRestaurantNameLabel} 
                        rqsContactPreferenceLabel={this.props.rqsContactPreferenceLabel} 
                        rqsPhoneNumberLabel={this.props.rqsPhoneNumberLabel} 
                        rqsEmailLabel={this.props.rqsEmailLabel}
                        rqsEmailOptionLabel={this.props.rqsEmailOptionLabel} 
                        rqsPhoneOptionLabel={this.props.rqsPhoneOptionLabel} 
                    />}
                </div>
                
            )
        }
    }
    
export default FirstLastName