import React from 'react';
import RestaurantSeatingChart from './RestaurantSeatingChart';

class RestaurantColorScheme extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showRestaurantSeatingChart: false
            };

            this.handleChange = this.handleChange.bind(this);
            this.onClickGenerateColors = this.onClickGenerateColors.bind(this);
        }

        handleChange(event) {
            event.preventDefault();
            this.setState({showRestaurantSeatingChart: true});
        }

        onClickGenerateColors(event) {
            event.preventDefault();
            alert("I think this would be a big help for restaurants because it would eliminate the need of bringing in a marketing person to develop a brand htmlFor them. \n Through an equation we could generate a color scheme based on their logo, restaurant, and cuisine type that will fit their restaurant. If they hit the button again it will regenerate a different set of colors that would still fit.");
        }

        render() {
            const { showRestaurantSeatingChart } = this.state;
            return (
                <div id="rqsRestaurantColorSchemeItem">
                    <div className="rqsInverseDiv" >
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsRestaurantLogo">{this.props.rqsRestaurantColorSchemeLabel} {this.props.restaurantNameInput}?</label>
                            <div>
                                <button onClick={this.onClickGenerateColors}>{this.props.rqsRestaurantGenerateColorLabel}</button>
                                <div id="brandColorPicker">
                                    <span className="themeColorSpan">
                                        <input type="color" id="themeColor1" className="rqsColorInput" defaultValue="#ffffff"/>
                                    </span>
                                    <span className="themeColorSpan">
                                        <input type="color" id="themeColor2" className="rqsColorInput" defaultValue="#ffffff"/>
                                    </span>
                                    <span className="themeColorSpan">
                                        <input type="color" id="themeColor3" className="rqsColorInput" defaultValue="#ffffff"/>
                                     </span>
                                    <br/>
                                    <button>{this.props.rqsRestaurantAddColorLabel}</button>
                                </div>
                                <div id="submitBrandColors">
                                    <button onClick={this.handleChange}>{this.props.rqsLooksGoodLabel}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showRestaurantSeatingChart && <RestaurantSeatingChart
                        restaurantNameInput={this.props.restaurantNameInput}
                        rqsRestaurantCuisineLabel={this.props.rqsRestaurantCuisineLabel} 
                        rqsRestaurantHoursLabel={this.props.rqsRestaurantHoursLabel} 
                        rqsRestaurantLogoLabel={this.props.rqsRestaurantLogoLabel} 
                        rqsYes={this.props.rqsYes} 
                        rqsNo={this.props.rqsNo} 
                        rqsSkipLabel={this.props.rqsSkipLabel} 
                        rqsRestaurantColorSchemeLabel={this.props.rqsRestaurantColorSchemeLabel} 
                        rqsRestaurantGenerateColorLabel={this.props.rqsRestaurantGenerateColorLabel} 
                        rqsRestaurantAddColorLabel={this.props.rqsRestaurantAddColorLabel} 
                        rqsLooksGoodLabel={this.props.rqsLooksGoodLabel} 
                        rqsRestaurantSeatingChartLabel={this.props.rqsRestaurantSeatingChartLabel} 
                        rqsRestaurantSeatingChartYesGenerateLabel={this.props.rqsRestaurantSeatingChartYesGenerateLabel}
                        rqsRestaurantSeatingChartYesMakeLabel={this.props.rqsRestaurantSeatingChartYesMakeLabel} 
                        rqsRestaurantSeatingChartYesLaterLabel={this.props.rqsRestaurantSeatingChartYesLaterLabel} 
                        rqsRestaurantSeatingChartNoNeedLabel={this.props.rqsRestaurantSeatingChartNoNeedLabel} 
                        rqsRestaurantSeatingChartLeaveLabel={this.props.rqsRestaurantSeatingChartLeaveLabel} 
                        rqsRestaurantDeliveryOptionsLabel={this.props.rqsRestaurantDeliveryOptionsLabel} 
                        rqsRestaurantDineIn={this.props.rqsRestaurantDineIn} 
                        rqsRestaurantPickUpLabel={this.props.rqsRestaurantPickUpLabel}  
                        rqsRestaurantInHouseDeliveryLabel={this.props.rqsRestaurantInHouseDeliveryLabel}
                        rqsRestaurantThirdPartyDeliveryLabel={this.props.rqsRestaurantThirdPartyDeliveryLabel}  
                        rqsRestaurantThirdPartyDeliveryLeaveLabel={this.props.rqsRestaurantThirdPartyDeliveryLeaveLabel}
                    />}
                </div>
                
            )
        }
    }
    
export default RestaurantColorScheme