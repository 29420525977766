import React from 'react';
import StaffTable from './RQSComponents/Staff/StaffTable';
import RQScomplete from './RQScomplete';
class RQSstaff extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showComplete: false};
        
            this.onButtonClick = this.onButtonClick.bind(this);
        }

        onButtonClick() {
            this.setState({ showComplete: true });
        }

        render() {
            const { showComplete } = this.state;
            return (
                <div className="rqsStaff">
                    <h2><strong>Who helps you?</strong></h2>
                    <StaffTable />
                    <br />
                    <button onClick={this.onButtonClick}>Looks good</button>
                    <br />
                    {showComplete && <RQScomplete />}
                </div>
               
            )
        }
    }
    
export default RQSstaff
    