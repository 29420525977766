import React from 'react';
import MenuContainer from './RQSComponents/Menu/MenuContainer';
import RestaurantLocation from './RQSComponents/RestaurantInfo/RestaurantLocation';

class RQSrestaurantInfo extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showMenu: false};
        
            this.handleSubmit = this.handleSubmit.bind(this);
        }
        
        handleSubmit(event) {
            event.preventDefault();
            var eventIterator = 0;
            var updatedState = {}
            updatedState.showMenu = true;

            var name = "";
            var value = "";
            while (event.target[eventIterator] != null) {
                name = event.target[eventIterator].name;
                value = event.target[eventIterator].value;
                updatedState[name] = value; 
                eventIterator++;
            }
            this.setState(updatedState);
        }

        render() {
            const { showMenu } = this.state;
            return (
                <div className="rqsRestaurant">
                    <h2><strong>Tell us about {this.props.rqsRestaurantName}</strong></h2>
                    <form id="restaurantInfoForm" className="rqsForm" onSubmit={this.handleSubmit}>				
                            <RestaurantLocation
                                rqsRestaurantName={this.props.rqsRestaurantName}
                                rqsRestaurantLocationLabel={this.props.rqsRestaurantLocationLabel} 
                                rqsRestaurantTypeLabel={this.props.rqsRestaurantTypeLabel} 
                                rqsRestaurantCuisineLabel={this.props.rqsRestaurantCuisineLabel} 
                                rqsRestaurantHoursLabel={this.props.rqsRestaurantHoursLabel} 
                                rqsRestaurantLogoLabel={this.props.rqsRestaurantLogoLabel} 
                                rqsYes={this.props.rqsYes} 
                                rqsNo={this.props.rqsNo} 
                                rqsSkipLabel={this.props.rqsSkipLabel} 
                                rqsRestaurantColorSchemeLabel={this.props.rqsRestaurantColorSchemeLabel} 
                                rqsRestaurantGenerateColorLabel={this.props.rqsRestaurantGenerateColorLabel} 
                                rqsRestaurantAddColorLabel={this.props.rqsRestaurantAddColorLabel} 
                                rqsLooksGoodLabel={this.props.rqsLooksGoodLabel} 
                                rqsRestaurantSeatingChartLabel={this.props.rqsRestaurantSeatingChartLabel} 
                                rqsRestaurantSeatingChartYesGenerateLabel={this.props.rqsRestaurantSeatingChartYesGenerateLabel}
                                rqsRestaurantSeatingChartYesMakeLabel={this.props.rqsRestaurantSeatingChartYesMakeLabel} 
                                rqsRestaurantSeatingChartYesLaterLabel={this.props.rqsRestaurantSeatingChartYesLaterLabel} 
                                rqsRestaurantSeatingChartNoNeedLabel={this.props.rqsRestaurantSeatingChartNoNeedLabel} 
                                rqsRestaurantSeatingChartLeaveLabel={this.props.rqsRestaurantSeatingChartLeaveLabel} 
                                rqsRestaurantDeliveryOptionsLabel={this.props.rqsRestaurantDeliveryOptionsLabel} 
                                rqsRestaurantDineIn={this.props.rqsRestaurantDineIn} 
                                rqsRestaurantPickUpLabel={this.props.rqsRestaurantPickUpLabel}  
                                rqsRestaurantInHouseDeliveryLabel={this.props.rqsRestaurantInHouseDeliveryLabel}
                                rqsRestaurantThirdPartyDeliveryLabel={this.props.rqsRestaurantThirdPartyDeliveryLabel}  
                                rqsRestaurantThirdPartyDeliveryLeaveLabel={this.props.rqsRestaurantThirdPartyDeliveryLeaveLabel}
                            />
                    </form>	

                    {showMenu && <MenuContainer />}
                </div>
            )
        }
    }
    
export default RQSrestaurantInfo