import React from 'react';
import MenuItems from './MenuItems';

class BuildMenu extends React.Component {
        constructor(props) {
            super(props);
            this.state = {value: '', showMenuItems: false};
            this.handleChange = this.handleChange.bind(this);
        }

        handleChange(event) {
            if(event.target.value === this.props.rqsYes) {
                this.setState({value: event.target.value, showMenuItems: true});
            } else {
                this.setState({value: event.target.value, showMenuItems: false});
            }
        }

        render() {
            const { showMenuItems } = this.state;
            return (
                <div id="rqsMenuBuildItem">
                    <div className="rqsDiv" onChange={this.handleChange}>
                        <div className="rqsInputContainer">
                            <label className="rqsLabel" htmlFor="rqsMenuBuild">{this.props.rqsMenuBuildLabel}</label>
                            <br />
                            <span className="yesNoSpan">
                                <input id="rqsMenuBuild" name="rqsMenuBuild" className="rqsInput rqsCheckbox checkbox" type="radio" value={this.props.rqsYes} checked={this.state.value === this.props.rqsYes}/>
                                <label className="choice" htmlFor="rqsMenuBuild">{this.props.rqsYes}</label>
                            </span>
                            <span className="yesNoSpan">
                                <input id="rqsMenuBuild" name="rqsMenuBuild" className="rqsInput rqsCheckbox checkbox" type="radio" value={this.props.rqsNo} checked={this.state.value === this.props.rqsNo}/>
                                <label className="choice" htmlFor="rqsMenuBuild">{this.props.rqsNo}</label>
                            </span>
                        </div>
                    </div>
                    {showMenuItems && <MenuItems />}
                </div>
            )
        }
    }
    
export default BuildMenu