import React from 'react';
import { useTranslation } from "react-i18next";
import { MDBIcon } from 'mdbreact';

function Footer() {
    const { i18n } = useTranslation();

    /* Changes the languages based on flag click, defaults to English */
    const changeLanguage = (event) => {
        i18n.changeLanguage(event.currentTarget.value);
    }
    return (
        <nav className="footerSection">
            <ul>
                <li>
                    <button value="en" onClick={changeLanguage} className="flagButton">Eng</button >
                </li>
                <li>
                    <button value="es" onClick={changeLanguage} className="flagButton">Esp</button >
                </li>
                <li>
                    <button value="zh" onClick={changeLanguage} className="flagButton">中文</button >
                </li>
                <li className="footerEmail">
                    willhofkamp123@gmail.com
                </li>
                <li>
                    <a href="https://Linkedin.com/in/will-hofkamp-528603186" rel="noopener noreferrer"><MDBIcon fab icon="linkedin" /></a>
                </li>
                <li>
                    <a href="https://github.com/WillHofkamp" rel="noopener noreferrer"><MDBIcon fab icon="github-square" /></a>
                </li>
                <li>
                    <a href="https://Instagram.com/WillHofkamp" rel="noopener noreferrer"><MDBIcon fab icon="instagram" /></a>
                </li>
            </ul>
        </nav>
        )
}
        
export default Footer