import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import profilepic from '../img/profile_photo.jpg';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MDBIcon } from 'mdbreact';

function SplashPage() {
    const { t, i18n } = useTranslation();

    return (
        <div className="home">
        <a href="/about">
            <img src={profilepic} alt="ProfilePic" className="profilepic"></img>
        </a>
        <h1 className="describerText">{t('describer.label')}</h1><ReactTypingEffect className="typingeffect" text={i18n.t('typingTextOptions.label', { returnObjects: true })} speed={100} eraseDelay={500}/>
        <br/>
        <br/>
        <Link to="/about" className="downButton"><MDBIcon icon="arrow-circle-down" size="4x" /></Link>
        </div>
    )
}

export default SplashPage
